import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { onEditorReady } from './editor-app/edirotReady';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { AppManifest } from '@wix/app-manifest-builder';
import { registerEventListeners } from './editor-app/eventHandlers';

export const onEvent = (...arg: any) => {
  console.log(arg);
};

export const editorReady: EditorReadyFn = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI,
): Promise<void> => {
  await registerEventListeners(editorSDK, options, flowAPI);
  return onEditorReady(editorSDK, appDefId, options);
};

export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
